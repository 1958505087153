/* eslint-disable consistent-return */
import { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { imageVar } from '@core/services/graphql/cache';

const useHandlingPlayPauseVideoProduct = () => {
    const cacheImage = useReactiveVar(imageVar);
    const [numberPlayVideo, setNumberPlayVideo] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            const videos = document.querySelectorAll('.video-wrapper video');
            if (videos && videos.length) {
                const observer = new IntersectionObserver(
                    (entries) => {
                        entries.forEach((entry) => {
                            if (entry.isIntersecting) {
                                entry.target.play();
                            } else {
                                entry.target.pause();
                            }
                        });
                    },
                    {
                        threshold: 0.5, // Mean if component video on viewport 50%
                    },
                );

                let curentVideoPlay = numberPlayVideo;

                const playNextVideo = () => {
                    if (curentVideoPlay < videos.length - 1) {
                        observer.observe(videos[curentVideoPlay + 1]);
                        const nextNumber = curentVideoPlay + 1;
                        setNumberPlayVideo(nextNumber);
                        curentVideoPlay = nextNumber;
                    }
                };

                videos.forEach((video) => {
                    video.addEventListener('ended', playNextVideo);
                });

                if (videos.length > 0) {
                    videos[curentVideoPlay].play();
                }
            }
        }, 100);
    }, [cacheImage]);
};

export default useHandlingPlayPauseVideoProduct;
